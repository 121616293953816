<template>
  <div class="leftTopContainer flex-col">
    <div class="titleBox">
      {{ $t("market.marketText") }}
    </div>
    <div class="tabsBox">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane
          :label="$t('market.digitalCurrency')"
          name="1"
        ></el-tab-pane>
        <el-tab-pane :label="$t('market.nobleMetal')" name="6"></el-tab-pane>
        <el-tab-pane
          :label="$t('market.foreignExchange')"
          name="5"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <div class="listBox">
      <template v-if="activeName == '1'">
        <div
          v-for="(item, index) in digitalCurrency.quotation"
          :key="index"
          :class="{ active: activeList == item.id }"
          class="cur-p itemList"
          @click="handListClick(item)"
        >
          <div class="flex jus-bet">
            <span>{{ item.currency_name }}/{{ item.legal_name }}</span>
            <span :class="item.change > 0 ? 'red' : 'green'">{{
              item.now_price
            }}</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          v-for="(item, index) in metalList"
          :key="index"
          class="cur-p itemList"
          :class="{ active: activeList == item.productdata.id }"
          @click="handListClick(item)"
        >
          <div class="flex jus-bet">
            <span>{{ item.ptitle }}</span>
            <span :class="item.isup == 1 ? 'red' : 'green'">{{
              item.productdata.price
            }}</span>
          </div>
        </div>
      </template>
      <el-empty
        v-if="activeName == '1' && digitalCurrency.quotation.length == 0"
        :image-size="100"
      ></el-empty>
      <el-empty
        v-if="(activeName == '5' || activeName == '6') && metalList.length == 0"
        :image-size="100"
      ></el-empty>
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from "vue";
const emit = defineEmits(["getDetailInfo", "reset"]);
const props = defineProps({
  digitalCurrency: {
    type: Object,
    default: () => {},
  },
  metalList: {
    type: Array,
    default: () => [],
  },
});
// 顶部tab
const activeName = ref("1");
const handleClick = (e) => {
  emit("reset", activeName.value);
};
// watch(
//   () => props.digitalCurrency,
//   (newVal) => {
//     if(activeName.value == '1' && newVal.quotation.length > 0) {
//       console.log(2)
//       activeList.value = newVal.quotation[0].id;
//     }
//   }, {
//     deep: true
//   }
// );
// watch(
//   () => props.metalList,
//   (newVal) => {
//     if(activeName.value != '1' && newVal.length > 0) {
//       activeList.value = newVal[0].productdata.id;
//     }
//   }, {
//     deep: true
//   }
// );
// 当前选中
const activeList = ref(null);
// 传出数据
const objData = ref({});
// 点击列表
const handListClick = (item) => {
  if (activeName.value == "1") {
    activeList.value = item.id;
  } else {
    activeList.value = item.productdata.id;
  }
  objData.value = item;
  emit("getDetailInfo", activeName.value, objData.value);
};
const setActiveList = (id) => {
  activeList.value = id;
};
const setActiveName = (name) => {
  activeName.value = name;
};
defineExpose({
  setActiveName,
  setActiveList
});
</script>

<style lang="scss" scoped>
.leftTopContainer {
  padding: 4px;
  box-sizing: border-box;
  height: 100%;
  .titleBox {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    margin: 5px 0;
  }
  .tabsBox {
    margin-bottom: 5px;
    :deep(.el-tabs__header) {
      margin: 0;
    }
    :deep(.el-tabs__item) {
      font-size: 14px;
      margin-right: 5px;
      border-radius: 3px;
    }
  }
  .listBox {
    flex: 1;
    height: 1px;
    overflow-y: auto;
    .itemList {
      font-size: 14px;
      border: 1px solid #e2e2e2;
      margin-top: 5px;
      border-radius: 5px;
      padding: 2px 15px;
      box-sizing: border-box;
      &.active {
        background: #5670f6;
        border: 1px solid $market-list-hover;
        span {
          color: #fff !important;
        }
      }
      &:hover {
        background: #5670f6;
        border: 1px solid $market-list-hover;
        span {
          color: #fff !important;
        }
      }
    }
  }
}
</style>
